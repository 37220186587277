@charset "UTF-8";

.slick-slider {
  outline: none;
}

.slick-dots {
  bottom: 10px;
}

.slick-dots-mobile {
  margin-bottom: 0;
}

.slick-dots li {
  margin: 0 0.3rem;
  height: 15px;
  width: 15px;
}

.slick-dots li button {
  border-radius: 10px;
  transition: 0.3s;
  background: white;
  height: 15px;
  width: 15px;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: none;
}

.slick-dots li.slick-active button {
  background: #ff4100;
}
